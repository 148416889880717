<script>
    import CardFilter from "./CardFilter.svelte";
    import { fade, crossfade, scale } from "svelte/transition";
    import { paginate, LightPaginationNav } from "svelte-paginate";
    import { onMount } from "svelte";

    let model = [];
    export let data = [];
    export let detailOverview = 0;
    export let contentData = [];
    export let getValideValue;
    export let getValideList = null;
    export let einheitVerbrauch = null;
    export let bezeichnungVebrauch = null;

    let filterCount = 0;
    let showFilters = false;
    let tmpSortDirection;
    let sortDirection;
    let currentPage = 1;
    let pageSize = 12;

    let clsShaddow = "";
    let filterItems = [];
    let tmpData = [];
    let tmpSearch = '';
    let searchText = '';
    const urlSearchParams = new URLSearchParams(window.location.search);
    let showManufacturer = false;
    let filterValue = urlSearchParams.get('Filter');
    filterValue = ((filterValue) ? filterValue : (window.location.hash.slice(1) ? window.location.hash.slice(1) : 'all'))
    onMount(() => {
        if (filterValue !== "all" || filterValue !== 'beresa-abosite') {
            showManufacturer = (filterValue === 'beresa-abosite') ? true : false;
            if(showManufacturer) {
                handleSearch({target: {form: {elements: {"model[]": {value: filterValue}}}}});
            }
        }
        filterCount = data.length;
        tmpData = renderData(pageSize, currentPage);
    });

    const kWhtoPS = (kWh) => {
        const kW = kWh;
        const PS = kW * 1.3596;
        return Math.round(Math.round(PS * 100) / 100);
    }

    const showEff = (data) => {
        if(data === 'AAAAA') {
            data = 'A++++';
        }
        else if(data === 'AAAA') {
            data = 'A+++';
        }
        else if(data === 'AAA') {
            data = 'A++';
        }
        else if(data === 'AA') {
            data = 'A+';
        }
        return data;
    }

    function scrollToId(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    
    const load = (data) => {
      detailOverview = 1;
      //scrollToId('beresa-abosite');
      contentData = data;
    };
  
    const [send, receive] = crossfade({
        duration: 200,
        fallback: scale,
    });

    function goToPage(page) {
        currentPage = page;
    }
    function renderData(pageSize, currentPage, resultData) {
        let items = (typeof resultData !== 'undefined') ? resultData : data;
        let result = paginate({ items, pageSize, currentPage });
        //scrollToId('beresa-abosite');
        return result;
    }

    function filteredData() {
        let result;
        let tmpSearch = searchText;
        let tmpSearchArray = Array.isArray(tmpSearch) ? tmpSearch : [tmpSearch]; // tmpSearch in ein Array umwandeln
        if (tmpSearch === '' || tmpSearch.length === 0) {
            result = data;
        } else {
            result = data.filter(item => {
                return tmpSearchArray.some(value => {
                    return ((item.attributes.Titel+' '+item.attributes.Teasertext+' '+item.attributes.Getriebe+' '+item?.attributes.Ausstattung+' '+(item.attributes.Ausstattung[value] == true ? value : '')).toLowerCase().includes(value.toLowerCase()));// || item.attributes.Teasertext.toLowerCase().includes(value.toLowerCase()));
                });
            });
        }
        tmpData = result;
        filterCount = result.length;
        if(filterCount <= pageSize) {
            goToPage(1);
        }
        return renderData(pageSize, currentPage, result);
    }

    function handleSearch(event) {
        const checkboxen = event.target.form.elements["model[]"];
        let selectedManufacturers = Array.from(checkboxen).filter(cb => cb.checked).map(cb => cb.value.replaceAll(' ','_'));
        // Prüfe, ob Hersteller ausgewählt ist
        if (selectedManufacturers.length > 0) {
            searchText = selectedManufacturers;
        }
        tmpSearch = searchText;
    }

    function sortItems() {
        tmpSortDirection = sortDirection;
        let filterItems = (data.sort((a, b) => sortDirection * (a?.attributes?.Basispreis - b?.attributes?.Basispreis))).length > 0 ? data.sort((a, b) => sortDirection * (a?.attributes?.Basispreis - b?.attributes?.Basispreis)) : null;
        return renderData(pageSize, currentPage, filterItems);
    }

    $: filterItems = ((tmpSortDirection != sortDirection) ? sortItems() : renderData(pageSize, 1, filterItems)) || (searchText !== '' || searchText != tmpSearch) ? filteredData() : renderData(pageSize, currentPage);

</script>

<section id="abositetop" class="pb-20 block">
    <div class="lg:pt-6 pt-6 md:w-4/4 text-center">
        <div class="flex flex-wrap items">
            <div class="pt-6 h-3/4 lg:w-6/12 md:w-6/12 sm:w-6/12 px-2 text-left font-semibold">
                <div class="rounded-lg filterbar" on:mouseup={() => {
                        showFilters =! showFilters
                    }}>
                    <span class="count">{filterCount} Fahrzeuge filtern &#xE16E;</span>
                </div>
                <CardFilter {model} {showFilters} {handleSearch}></CardFilter>
            </div>
            <div on:mouseup={() => {
                showFilters = false
            }}
            class="pt-6 h-4/4 lg:w-6/12 md:w-6/12 sm:w-6/12 px-2 text-right"
            >
                <select class="sortbar" bind:value={sortDirection}>
                    <option value="1">Preis aufwärts sortieren</option>
                    <option value="-1">Preis abwärts sortieren</option>
                </select>
            </div>
        </div>
        <div on:mouseup={() => {showFilters = false; scrollToId('abositetop')}} class="flex flex-wrap items mb-4 pt-2">
        {#each filterItems as row, i (row)}
            <div on:mouseup={() => {
                showFilters = false
            }}
                class="pt-6 h-3/4 lg:w-3/12 md:w-6/12 sm:w-6/12 px-2 text-center item"
                id={row.id}
                on:mouseenter={() => (clsShaddow = i)}
                on:mouseleave={() => (clsShaddow = "")}
                on:mousedown={() => load(row)}
                on:mouseup={() => {
                    showFilters = false;
                }}
                in:receive={{ key: row.id }}
                out:send={{ key: row.id }}
            >
                <div
                class="flex flex-col min-w-0 break-words bg-white lg:w-full mb-8 rounded-lg {clsShaddow ===
                i
                    ? 'shadow-lg'
                    : 'shadow-sm'}"
                >
                <div
                    style="position:relative; background-image: url(https://beresa.machs-mit-marketing.de/abosite/image/id/{row.id}/format/small);"
                    class="image-preview lg:h-auto md:h-96 sm:h-72 max-w-full lg:pt-24 md:pt-12 sm:pt-12 lg:w-96 shadow-sm rounded-t-lg bg-cover bg-left-bottom bg-no-repeat"
                >
                    <div style="min-height:100px; position:absolute; right:0; top:30px;">
                        {#if getValideValue(row?.attributes?.Informationen , 'Antrieb') != '---'}<p style="text-align:right; color:#fff; padding:4px; margin-right:0; margin-bottom:4px; font-size:12px;"><span style="background-color: #6d97a1; font-size:14px; padding:2px; margin-right:0; min-width:70px;">{getValideValue(row?.attributes?.Informationen , 'Antrieb')}</span></p>{/if}
                        <!--{#if getValideValue(row?.attributes , 'Availability') != '-'}<p style="text-align:right; color:#fff; padding:4px; margin-bottom:4px; font-size:11px;"><span style="background-color: #6d97a1; padding:2px;">{getValideValue(row?.attributes , 'Availability')}</span></p>{/if}-->
                    </div>
                </div>
                <div class="lg:pt-6 mt-4 min-h-380px flex-auto text-left" style="min-height:400px; padding:6px;">
                    <h6 class="text-xl p-12 font-semibold">{getValideValue(row?.attributes?.Hersteller?.data?.attributes, 'Hersteller')}<br />{getValideValue(row?.attributes, 'Titel')}</h6>
                        {#if getValideList(row?.attributes,'Ausstattung').length > 0}
                        <ul class="mt-2 mb-4 p-12 text-blueGray-500">
                        {#each getValideList(row?.attributes,'Ausstattung') as [k, v], index}  
                            {#if v === true}
                            <li class="mt-0" style="color:#6d97a1;">&#x2713; {k.replaceAll("_", " ")}</li>
                            {/if}
                        {/each}
                        </ul>
                        {:else}
                        <small>Es liegen im Moment keine weiteren Informationen vor.</small>
                        {/if}
                    <small style="line-height:8px;">
                        {#if getValideValue(row?.attributes?.Informationen, 'Leistung') !== '---'}
                            Leistung {getValideValue(row?.attributes?.Informationen, 'Leistung')} {einheitVerbrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb')) === 'l' ? 'kW/l' : 'kW'} ({kWhtoPS(getValideValue(row?.attributes?.Informationen, 'Leistung'))} PS); 
                        {/if}
                        {#if getValideValue(row?.attributes?.Emissionen, 'Kraftstoffverbrauch_kombiniert') !== '---'}
                            {bezeichnungVebrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb'))} kombiniert <sup>2,3</sup> {getValideValue(row?.attributes?.Emissionen, 'Kraftstoffverbrauch_kombiniert')} {einheitVerbrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb'))}/100Km; 
                        {/if}
                        {#if getValideValue(row?.attributes?.Emissionen, 'Kraftstoffverbrauch_innerorts') !== '-'}
                            {bezeichnungVebrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb'))} innerorts <sup>2,3</sup> {getValideValue(row?.attributes?.Emissionen, 'Kraftstoffverbrauch_innerorts')} {einheitVerbrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb'))}/100Km; 
                        {/if}
                        {#if getValideValue(row?.attributes?.Emissionen, 'Kraftstoffverbrauch_ausserorts') !== '-'}
                            {bezeichnungVebrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb'))} außerorts <sup>2,3</sup> {getValideValue(row?.attributes?.Emissionen, 'Kraftstoffverbrauch_ausserorts')} {einheitVerbrauch(getValideValue(row?.attributes?.Informationen, 'Antrieb'))}/100Km; 
                        {/if}

                        {#if getValideValue(contentData?.attributes?.Emissionen, 'CO2_Klasse_Kombiniert') >= 0}
                            CO₂-Klasse kombiniert {getValideValue(contentData?.attributes?.Emissionen, 'CO2_Klasse_Kombiniert')}
                        {/if}
                        {#if getValideValue(contentData?.attributes?.Emissionen, 'CO2_Klasse_gew_Kombiniert') >= 0}
                            CO₂-Klasse gewichtet kombiniert {getValideValue(contentData?.attributes?.Emissionen, 'CO2_Klasse_gew_Kombiniert')}
                        {/if}
                        {#if getValideValue(contentData?.attributes?.Emissionen, 'CO2_Klasse_entl_Batterie') >= 0}
                            CO₂-Klasse bei entladener Batterie {getValideValue(contentData?.attributes?.Emissionen, 'CO2_Klasse_entl_Batterie')}
                        {/if}
                        
                        {#if getValideValue(row?.attributes?.Emissionen, 'CO2_Emission_kombiniert') >= 0}
                            CO₂-Emission kombiniert {getValideValue(row?.attributes?.Emissionen, 'CO2_Emission_kombiniert')} g/Km; 
                        {/if}
                        {#if getValideValue(row?.attributes?.Emissionen, 'Effizienzklasse') >= 0}
                            CO₂-Effizienz {showEff(row?.attributes?.Emissionen.Effizienzklasse)}
                        {/if}
                    </small>
                </div>
               
                <div class="lg:pt-6 mt-4 flex-auto text-center;" style="padding:12px; text-align: center; color:#6d97a1 !important">
                    {#if getValideValue(row?.attributes , 'Availability') != '-'}<h6 class="text-xl p-6 font-semibold">{getValideValue(row?.attributes , 'Availability')}</h6>{/if}&nbsp;                    
                </div>
                
                <div class="lg:pt-6 mt-4 flex-auto text-right" style="padding:6px;">
                    <h6 class="text-xl p-12 font-semibold relative">
                        {#if getValideValue(row?.attributes , 'UVPPreis') != '-'}
                            <small class="strike-through">(UVP {getValideValue(row?.attributes , 'UVPPreis')} €)</small>
                        {/if}
                        ab {getValideValue(row?.attributes, 'Basispreis')} €
                    </h6><small>mtl. inkl. MwSt</small>
                </div>
            </div>
        </div>
    {/each}
    </div>
    {#if filterItems.length <= 0}
    <div class="lg:pt-6 pt-6 md:w-4/4 px-4 text-center">
        <h2>KEINE PASSENDEN FAHRZEUGE GEFUNDEN.</h2>
        <p>Diese Filterkombination erzielt keine Treffer. Bitte wählen Sie eine andere<br />Filtereinstellungen, um mehr Ergebnisse zu erhalten.</p>
        <!--<button id="resetFilter" value="" on:mouseup={model = null}>Filter zurücksetzen</button>-->
    </div>
    {:else}
    <div class="lg:pt-6 pt-6 md:w-4/4 px-4 text-center">
        <div class="flex flex-col min-w-0 break-words mb-8">
            <div class="px-4 py-5 flex-auto" on:mouseup={() => {
                showFilters = false;
                scrollToId('abositetop')
            }}>
                <LightPaginationNav
                    totalItems={tmpData.length}
                    {pageSize}
                    {currentPage}
                    limit={1}
                    showStepOptions={true}
                    on:goToStep{goToPage}
                    on:setPage={(e) => (currentPage = e.detail.page)}
                />
            </div>
        </div>
    </div>
    {/if}
</section>

<style>
.strike-through {
    position: relative;
}

.strike-through::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    border-top: 2px solid red; /* Farbe und Dicke des Durchstreichens anpassen */
    transform: rotate(-15deg);
    transform-origin: center;
}
.filterbar {
    background-color: #6d97a1;
    padding: 8px;
    margin: 2px;
    line-height: 20px;
    min-width: 160px;
    text-align: center;
    display: inline-block;
    color: #ffffff;
}
.sortbar {
    font-size: 18px !important;
    border-color: #6d97a1;
    padding: 8px;
    margin: 2px;
    line-height: 20px;
    min-width: 240px;
    display: inline-block;
    color: #000000;
}

:global(.image-preview) {
    min-height: 270px !important;
}

/* Stil für Mobilansicht (maximale Breite 350px) */
@media (min-width: 240px) and (max-width: 480px) {
    :global(.image-preview) {
        min-height: 300px !important;
    }
}

/* Stil für Standardansicht (maximale Breite 600px) */
@media (min-width: 481px) and (max-width: 600px) {
    :global(.image-preview) {
        min-height: 280px !important;
    }
}

@media (min-width: 601px) and (max-width: 1280px) {
    :global(.image-preview) {
        min-height: 270px !important;
    }
}
/*
.count {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: center;
    background-color: #fefeff;
    margin-left: 0.5rem;
    border-radius: 50%;
}

input {
    font-size: inherit;
    padding: 0.5rem 1rem 0.5rem 2rem;
    width: 15.75rem;
    border: none;
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: left 0.5rem center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='none' stroke='%234f4f4f' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' %3e%3ccircle cx='11' cy='11' r='8'%3e%3c/circle%3e%3cline x1='21' y1='21' x2='16.65' y2='16.65'%3e%3c/line%3e%3c/svg%3e");
    border-radius: 5rem;
    transition: all 0.25s ease;
}

input:focus {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}
*/

.flex {
    display: flex;
}
  </style>