<script>
  import { fade, crossfade, scale } from "svelte/transition";
  import { writable } from "svelte/store";
  import CardOverview from "./CardOverview.svelte";
  import CardDetails from "./CardDetails.svelte";
  
  export let data = [];
  export let detailOverview = 0;
  
  let contentData = writable([]);

  const [send, receive] = crossfade({
    duration: 200,
    fallback: scale,
  });

  function getData() {
    return data;
  }
  const einheitVerbrauch = (data) => {
      let einheitVerbrauch;
      if(data === "Elektrisch") {
          einheitVerbrauch = 'kWh';
      } else {
          einheitVerbrauch = 'l';  
      }
      return einheitVerbrauch;
  }

  const bezeichnungVebrauch = (data) => {
      let bezeichnungVebrauch;
      /*
      if(data === "Elektrisch") {
          bezeichnungVebrauch = 'Stromverbrauch';
      } else {
          bezeichnungVebrauch = 'Kraftstoffverbrauch';
      }*/
      bezeichnungVebrauch = 'Energieverbrauch';
      return bezeichnungVebrauch;
  }

  let getValideList = (mainObject, fiedName) => {
    return Object.entries(mainObject[fiedName] ?? []);
  }

  let getValideValue = (mainObject, fiedName) => {
    return (mainObject) ? (Object.is(mainObject) ? Object.entries(mainObject)[fiedName] : mainObject[fiedName]) ?? '-' : '---';
  }

  $: display = getData();

</script>

{#if detailOverview}
    <CardDetails bind:detailOverview = {detailOverview} contentData = {contentData} {getValideValue} {getValideList} {einheitVerbrauch} {bezeichnungVebrauch}></CardDetails>
{:else}
    <CardOverview data={display} bind:detailOverview = {detailOverview} bind:contentData = {contentData} {getValideValue} {getValideList} {einheitVerbrauch} {bezeichnungVebrauch}></CardOverview>
{/if}
  
<style>
  /*
  header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }
 
  h1 {
    font-size: 1.75rem;
    margin: 0 1rem 0 0;
    display: flex;
    align-items: center;
  }
  
  img.eff {
    width: 280px;
    height: auto;
    margin: -0.25rem -1.0rem;
    background-color: transparent;
    padding: 0;
  }
  
  .count {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: center;
    background-color: #fefeff;
    margin-left: 0.5rem;
    border-radius: 50%;
  }

  input {
    font-size: inherit;
    padding: 0.5rem 1rem 0.5rem 2rem;
    width: 15.75rem;
    border: none;
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: left 0.5rem center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='none' stroke='%234f4f4f' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' %3e%3ccircle cx='11' cy='11' r='8'%3e%3c/circle%3e%3cline x1='21' y1='21' x2='16.65' y2='16.65'%3e%3c/line%3e%3c/svg%3e");
    border-radius: 5rem;
    transition: all 0.25s ease;
  }

  input:focus {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  }

  .btn-back {
    font-family: "BeresaSans-Demi", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #FF005F;
    cursor: pointer;
  }

  .flex {
    display: flex;
  }
  */
</style>
